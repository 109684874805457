var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
(function ($) {
  Drupal.behaviors.productBriefV2 = {
    attached: false,
    imagePattern: 'mac_sku_PRODUCT_CODE_640x600_FILTER_VALUE',
    imageExtension: 'jpg',
    nodes: {},
    settings: {
      imagePath: '/media/export/cms/products/640x600/mpp/'
    },
    $grids: $(),

    selectors: {
      container: '.js-mpp-custom-override-layout',
      grid: '.js-product-grid',
      item: '.js-product-grid-item',
      product: '.js-product-ui',
      productImageContainer: '.js-product-image',
      productImageDefault: '.js-product-brief__image-img',
      productImageModel: '.js-product-brief__image-img--model',
      productImageAlt: '.js-product-brief__image-img--alt',
      productBadge: '.js-product-brief__badge',
      productBadgeContainer: '.js-product-brief__top',
      productLinkSpp: '.js-product__link-to-spp',
      productVtoLink: '.js-cta-vto-link',
      productVto: '.js-product-vto-link',
      productShade: '.js-sku-shade-name',
      productPrice: '.js-product-price',
      productPrice2: '.js-product-price--discounted',
      productSalePrice: 'js-product-price--sale'
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.$grids = $(self.selectors.grid, context);
      self.$grids.each(function () {
        self.setup($(this));
      });
      // Feature gate productBrief init to only cr21 sites
      if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr21_mpp) {
        $(document).on('productBrief.init', () => {
          self.$grids = $(self.selectors.grid, context);
          self.$grids.each(function () {
            self.setup($(this));
          });
        });
      }
    },

    setSettings: function ($that) {
      var self = this;

      $that.settings = $.extend({}, self.settings);
      $that.settings.imagePath = $that.data('imagePath') || $that.settings.imagePath;
    },

    setup: function ($that) {
      var self = this;

      $that.once('setup', function () {
        // update the $grids when setup is called after the attach
        self.$grids = self.$grids.add($that);
      });
      self.getDom($that);
      self.setSettings($that);
      self.setEvents($that);
      self.loadSkus($that);
      self.setRandomImageAvailable($that);
      $that.trigger('powerreviews-snippet-init');
    },
    earlyAccessCopy: function (prodID, context) {
      var product = prodcat.data.getProduct(prodID);
      if (!product) {
        return;
      }
      var skus = product.skus;
      if (!skus) {
        return;
      }
      var skusLength = skus.length;
      var $earlyAccess = $('.js-product-early-access', context);

      $earlyAccess.addClass('hidden');
      if (skusLength > 0) {
        for (var i = 0; i < skusLength; i++) {
          if (skus[i].RESTRICT_GROUP === 1) {
            $earlyAccess.removeClass('hidden');
            break;
          }
        }
      }
    },
    loadSkus: function ($that) {
      var self = this;

      $that.nodes.$products.each(function () {
        var $product = $(this);
        // if the product is shaded the skuSelect triggers from brief shade picker files, no reason to duplicate
        // there are cases where shades file loads before brief, in which case we still need to load the sku
        var processShade =
          ($product.data('isShaded') && $product.data('shadeSelected')) ||
          !$product.data('isShaded');

        if (!!$product.data('skuBaseId') && processShade) {
          $product.trigger('product.skuSelect', $product.data('skuBaseId'));
        } else {
          self.toggleVtoLinks($product, $product.data('skuBaseId'));
          // skuSelect triggers before grids are fully loaded, need to update the ShadeRoutes on load
          self.updateShadeRoutes($product, $product.data('skuBaseId'));
          self.updateSizeRoutes($product, $product.data('skuBaseId'));
        }
        if (!!$product.data('productId')) {
          self.earlyAccessCopy($product.data('productId'), $product);
        }
      });
    },
    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$container = $that.closest(self.selectors.container);
      $that.nodes.$productImages = $(self.selectors.productImageDefault, $that);
      $that.nodes.$productModelImages = $(self.selectors.productImageModel, $that);
      $that.nodes.$productAltImages = $(self.selectors.productImageAlt, $that);
      $that.nodes.$products = $(self.selectors.product, $that);
      $that.nodes.$items = $(self.selectors.item, $that);
    },

    setEvents: function ($that) {
      var self = this;

      $that.nodes.$container.on('mpp-toggle-image', function (e, dataOption) {
        self.changeImages($that, dataOption.toLowerCase());
      });
      $that.on('filterable_products:swapImage', function (e, eventData) {
        self.swapImage($that, eventData);
      });
      $that.on('filterable_products:randomSwapImage', function (e, eventData) {
        self.swapImage($that, eventData);
      });
      $that.on('slider.loaded', function () {
        self.setup($that);
      });

      $(document).on('product.skuSelect', $that.nodes.$products.selector, function (e, skuBaseId) {
        var $product = $(this);
        self.toggleVtoLinks($product, skuBaseId);
        self.updateSkuImage($product, $that, skuBaseId);
        // uncoment if they want to update the badge on sku change ( using products untill then )
        // self.updateSkuBadge($product, skuBaseId);
        self.updateShadeRoutes($product, skuBaseId);
        self.updateSizeRoutes($product, skuBaseId);
        self.updateShadeName($product, skuBaseId);
        self.updatePrice($product, skuBaseId);
      });
    },

    changeImages: function ($that, dataOption) {
      var option = dataOption === 'product' ? 'product' : 'model';
      var filterValue = $that.data('filterValueAsImage'); // set the value when filter based on the filter
      var randomValues = $that.data('randomValues');

      if (option === 'model') {
        if (filterValue) {
          $that.trigger('filterable_products:swapImage', { filterValue: filterValue });
        } else {
          $that.trigger('filterable_products:randomSwapImage', { randomValues: randomValues });
        }
        $that.nodes.$productImages.addClass('hidden');
        $that.nodes.$productModelImages.removeClass('hidden');
        $that.addClass('active-model');
      } else {
        $that.nodes.$productImages.removeClass('hidden');
        $that.nodes.$productModelImages.addClass('hidden');
        $that.removeClass('active-model');
      }
    },

    swapImage: function ($that, filterData) {
      var self = this;
      var src = '';
      var randomize = !!filterData.randomValues;

      $that.nodes.$productModelImages.each(function () {
        var $image = $(this);
        var pattern = self.imagePattern;

        if (randomize) {
          filterData.filterValue = self.getRandomImage($image, filterData.randomValues);
        }
        if (!filterData.filterValue) {
          return true;
        }

        filterData.filterValue = filterData.filterValue.replace(' ', '');
        pattern = pattern.replace('PRODUCT_CODE', $image.data('productCode'));
        pattern = pattern.replace('FILTER_VALUE', filterData.filterValue);
        src = self.settings.imagePath + pattern + '.' + self.imageExtension;
        if ($image.hasClass('lazyload')) {
          $image.data('src', src).attr('data-src', src);
        } else {
          $image.data('src', src).attr('data-src', src).attr('src', src);
        }
      });
    },

    updatePrice: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      if (!!skuData) {
        var shadePrice = skuData.formattedPrice;
        var shadePrice2 = skuData.PRICE2;
        var shadeFormattedPrice2 = skuData.formattedPrice2;
        var $updateShadePrice = $product.find(self.selectors.productPrice);
        var $updateShadePrice2 = $product.find(self.selectors.productPrice2);
        var $updateShadeSalePrice = $product.find(self.selectors.productSalePrice);
        if (!!shadePrice2) {
          $updateShadePrice2.html(shadeFormattedPrice2);
          $updateShadeSalePrice.html(shadePrice);
        } else {
          $updateShadePrice.html(shadePrice);
        }
      }
    },

    updateShadeName: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      if (skuData) {
        var shadeName = skuData.SHADENAME;
        var shadeNameHex = skuData.HEX_VALUE_STRING;
        var $shadeTitle = $product.find(self.selectors.productShade);
        if ($product.find('[title="' + shadeName + '"]').hasClass('low-contrast')) {
          $shadeTitle.addClass('low-contrast');
        } else {
          $shadeTitle.removeClass('low-contrast');
        }
        if ($product.find('[title="' + shadeName + '"]').hasClass('light-shade')) {
          $shadeTitle.addClass('light-shade');
        } else {
          $shadeTitle.removeClass('light-shade');
        }
        $shadeTitle.html(shadeName);
      }
    },

    updateSkuImage: function ($product, $that, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      var $image = $product.find($that.nodes.$productImages);
      var $altImage = $product.find($that.nodes.$productAltImages);
      var $toggleImage = $product.find($that.nodes.$productModelImages);
      var skuImageLarge = null;
      var altImageLarge = null;
      var skuImageMedium = null;
      var altImageMedium = null;
      var skuImage = null;
      var altImage = null;
      var useSrc = null;
      var newSrc = null;

      if (!!skuData) {
        skuImageLarge = Boolean(skuData.LARGE_IMAGE) && skuData.LARGE_IMAGE[0]
          ? skuData.LARGE_IMAGE[0]
          : null;
        altImageLarge = Boolean(skuData.LARGE_IMAGE) && skuData.LARGE_IMAGE[1]
          ? skuData.LARGE_IMAGE[1]
          : null;
        skuImageMedium = Boolean(skuData.MEDIUM_IMAGE) && skuData.MEDIUM_IMAGE[0]
          ? skuData.MEDIUM_IMAGE[0]
          : null;
        altImageMedium = Boolean(skuData.MEDIUM_IMAGE) && skuData.MEDIUM_IMAGE[1]
          ? skuData.MEDIUM_IMAGE[1]
          : null;
        skuImage = skuImageLarge || skuImageMedium;
        altImage = altImageLarge || altImageMedium;

        if (Drupal.settings.globals_variables.images_cr21) {
          skuImageLarge = Boolean(skuData.IMAGE_LARGE) && skuData.IMAGE_LARGE[0]
            ? skuData.IMAGE_LARGE[0]
            : null;
          altImageLarge = Boolean(skuData.IMAGE_LARGE) && skuData.IMAGE_LARGE[1]
            ? skuData.IMAGE_LARGE[1]
            : null;
          skuImageMedium = Boolean(skuData.IMAGE_MEDIUM) && skuData.IMAGE_MEDIUM[0]
            ? skuData.IMAGE_MEDIUM[0]
            : null;
          altImageMedium = Boolean(skuData.IMAGE_MEDIUM) && skuData.IMAGE_MEDIUM[1]
            ? skuData.IMAGE_MEDIUM[1]
            : null;
          skuImage = skuImageLarge || skuImageMedium;
          altImage = altImageLarge || altImageMedium;
        }

        if (!!skuImage) {
          // update main image
          self.setImageSrc($image, skuImage);
        }
        if (!!altImage) {
          // update alt image
          self.setImageSrc($altImage, altImage);
        }
        if ($toggleImage.is(':visible')) {
          // have to update the Model image if it's visible and user changes the shade
          useSrc = $toggleImage.attr('src') || $toggleImage.data('src');
          newSrc = useSrc.replace($toggleImage.data('productCode'), skuData.PRODUCT_CODE);

          self.setImageSrc($toggleImage, newSrc);
        }
        $toggleImage
          .data('productCode', skuData.PRODUCT_CODE)
          .attr('data-product-code', skuData.PRODUCT_CODE);
      }
    },

    updateSkuBadge: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);

      if (!!skuData.MISC_FLAG_TEXT) {
        var $badge = $product.find(self.selectors.productBadge);
        var $badgeContainer = $product.find(self.selectors.productBadgeContainer);

        if ($badge.length < 1) {
          $badge = $('<div class="product-brief__badge js-product-brief__badge"></div>');
          $badgeContainer.append($badge);
        }
        $badge.html(skuData.MISC_FLAG_TEXT);
      }
    },

    toggleVtoLinks: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      var $productVtoCtaWrapper = $product.find(self.selectors.productVto);

      $productVtoCtaWrapper.addClass('hidden');
      if (skuData && (skuData.VTO_FLAG_ONLINE || skuData.VTO_FOUNDATION_ONLINE)) {
        $productVtoCtaWrapper.removeClass('hidden');
      }
    },

    updateSizeRoutes: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      var productData = !!skuData ? prodcat.data.getProduct(skuData.PRODUCT_ID) : null;
      var isSized = !!productData ? productData.sized : false;
      if (isSized && skuData && !!skuData.PRODUCT_SIZE) {
        var $linksToSppVto = $product.find(self.selectors.productVtoLink);
        var $linksToSpp = $product.find(self.selectors.productLinkSpp).not($linksToSppVto);
        var processedSize = encodeURIComponent(skuData.PRODUCT_SIZE);

        self.updateSizeRouteLinks($linksToSppVto, processedSize);
        self.updateSizeRouteLinks($linksToSpp, processedSize);
      }
    },

    updateShadeRoutes: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);

      if (skuData && !!skuData.SHADENAME) {
        var $linksToSppVto = $product.find(self.selectors.productVtoLink);
        var $linksToSpp = $product.find(self.selectors.productLinkSpp).not($linksToSppVto);
        var shadeNameString = skuData.SHADENAME + '';
        var processedShadename = shadeNameString.replace(/\s/g, '_');

        processedShadename = encodeURIComponent(processedShadename).replace(
          /[!'()*]/g,
          function (c) {
            return '%' + c.charCodeAt(0).toString(16);
          }
        );
        self.updateShadeRouteLinks($linksToSppVto, processedShadename);
        self.updateShadeRouteLinks($linksToSpp, processedShadename);
      }
    },

    updateSizeRouteLinks: function ($links, processedShadename) {
      if ($links.length < 1) {
        return;
      }
      var linkPieces = $links.attr('href').split(/[?#]+/);
      var link = linkPieces[0];
      var linkParams = linkPieces.length > 2 ? '?' + linkPieces[2] : '';
      var shadeRoute = link + '?size=' + processedShadename + linkParams;

      $links.attr('href', shadeRoute);
    },

    updateShadeRouteLinks: function ($links, processedShadename) {
      if ($links.length < 1) {
        return;
      }
      var linkPieces = $links.attr('href').split(/[?#]+/);
      var link = linkPieces[0];
      var linkParams = linkPieces.length > 2 ? '?' + linkPieces[2] : '';
      var shadeRoute = link + '?shade=' + processedShadename + linkParams;

      $links.attr('href', shadeRoute);
    },

    setImageSrc: function ($image, imageSrc) {
      if (imageSrc) {
        if ($image.hasClass('lazyload')) {
          $image.attr('data-src', imageSrc);
        } else if ($image.attr('src') !== imageSrc) {
          $image.attr('src', imageSrc);
        }
      }
    },

    getRandomImage: function ($this, randomValues) {
      var availableValues = $this.data('availableRandomImages');

      randomValues = randomValues.split(',');
      availableValues = _.intersection(randomValues, availableValues);

      return availableValues[_.random(availableValues.length - 1)];
    },

    setRandomImageAvailable: function ($that) {
      var self = this;

      if (!$that.data('hasRandomization')) {
        return;
      }
      $that.nodes.$items.once('random-images-available').each(function () {
        var $item = $(this);

        if (
          !$item.data('attributes') ||
          (!$item.data('attributes').tags && !$item.data('attributes').skuTags)
        ) {
          return;
        }
        var tags = self.processTags($item.data('attributes').tags);
        var skuTags = self.processTags($item.data('attributes').skuTags);
        var availableRandomImages = $.extend({}, tags, skuTags);
        var $images = $item.find($that.nodes.$productModelImages);

        $images.data('availableRandomImages', availableRandomImages);
      });
    },

    processTags: function (tagsData) {
      if (typeof tagsData !== 'string') {
        return [];
      }
      var newData = tagsData.replace(/['`~!@#$%^&*()|+=?;:'".<>\{\}\[\]\\\/]/gi, '').toLowerCase();
      // Loop through our tags, split them by comma, then trim the whitespace
      var tags = _.map(newData.split(','), $.trim);

      return tags;
    }
  };
})(jQuery);
